
import {from as observableFrom} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

interface LoginResponse {
  success: boolean
}

/**
 * Service to login a user
 */
@Injectable()
export class LoginService {
  constructor(private http: HttpClient) {}

  login(username: string, password: string, remember_me: boolean): Observable<boolean> {
    return this.http.post<LoginResponse>('/auth', {
      username,
      password,
      remember_me
    }).pipe(
    map((res: LoginResponse) => res.success),
    catchError((err:any) => observableFrom([false])),);
  }
}