<div inject-icons style="display: none"></div>

<form class="login" (ngSubmit)="onSubmit()" #loginForm="ngForm">
  <div class="header">
    <h1>Connexion</h1>
  </div>
  <div class="content">
    <div class="message" *ngIf="!error">
      Veuillez vous identifier
    </div>

    <div class="message error" *ngIf="error">
      Nom d'utilisateur ou mot de passe incorrect
    </div>

    <div class="form-group">
      <div class="input-icon">
        <input type="text" placeholder="Nom d'utilisateur" name="username" [(ngModel)]="username" required autofocus />
        <div class="icons">
          <span class="icon-btn">
            <t-icon name="person"></t-icon>
          </span>
        </div>
      </div>
    </div>

    <div class="form-group">
      <div class="input-icon">
        <input type="password" placeholder="Mot de passe" name="password" [(ngModel)]="password" required />
        <div class="icons">
          <span class="icon-btn">
            <t-icon name="vpn_key"></t-icon>
          </span>
        </div>
      </div>
    </div>

    <div class="form-group btn-form-group">
      <button type="submit" class="btn btn-primary" [disabled]="!loginForm.valid">
        <t-icon name="arrow_forward"></t-icon>
        <span class="text">Connecter</span>
      </button>
    </div>

    <div class="form-group">
      <t-checkbox label="Se souvenir de moi" [(checked)]="remember_me"></t-checkbox>
    </div>
  </div>
</form>