import { Component } from '@angular/core';
import { LoginService } from '../login/login.service';

/**
 * Login form app
 */
@Component({
  selector: 'body',
  templateUrl: './app.component.html',
})
export class AppComponent {
  error: boolean = false;
  remember_me: boolean = true;
  username: string;
  password: string;

  constructor(private loginService: LoginService) {}

  onSubmit() {
    
    this.loginService
      .login(this.username, this.password, this.remember_me)
      .subscribe((success) => {
        if(success) {
          window.location.href = '/';
        }
        else {
          this.error = true;
        }
      });

    return false;
  }
}