import { Directive, ElementRef } from '@angular/core';
const icons = require('../../../../dist/public/images/icons.svg');

@Directive({
  selector: '[inject-icons]',
})
export class IconsDefinitionDirective {
  
  constructor(private element: ElementRef) {
     element.nativeElement.innerHTML = icons;
     element.nativeElement.style.display = 'none';
  }
}