import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 't-checkbox',
  templateUrl: './checkbox.component.html'
})
export class CheckboxComponent {
  @Input() label: string = '';


  private checkedValue: boolean = false;

  @Input() 
  get checked() {
    return this.checkedValue;
  }

  @Output() checkedChange = new EventEmitter();

  set checked(val) {
    this.checkedValue = val;
    this.checkedChange.emit(val);
  }

  toggle() {
    this.checked = !this.checked;
    return false;
  }
}