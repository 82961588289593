import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { IconsDefinitionDirective } from './icon/icons-definition.directive';
import { IconComponent } from './icon/icon.component';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule
  ],
  declarations: [
    CheckboxComponent,
    IconComponent,
    IconsDefinitionDirective
  ],
  exports: [
    CheckboxComponent,
    IconComponent,
    IconsDefinitionDirective
  ]
})
export class SharedUiModule { }
